<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Mi Code</label>
              <input
                type="text"
                class="form-control"
                v-model="micode.mi_code"
                placeholder="Enter Mi Code"
                required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Mi Name</label>
              <input
                type="text"
                class="form-control"
                v-model="micode.mi_name"
                placeholder="Enter Mi Name"
                required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Target Bill No</label>
              <input
                type="text"
                class="form-control"
                v-model="micode.target_bill_no"
                placeholder="Enter Target Bill No"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Target Bill Amount</label>
              <input
                type="text"
                class="form-control"
                v-model="micode.target_bill_amount"
                placeholder="Enter Target Bill Amount"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RS Code</label>
              <select required class="form-control" v-model="micode.revenue_supervisor_id" id="">
                <option v-for="rscodeList in rscodeLists" :value="rscodeList.id">{{ rscodeList.rs_code }}</option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "CreateMiCode",
  props: {
    rscodeLists: {
      type: Array,
      default: []
    }
  },
  data: () => {
    return {
      showToast: false,
      toastr: "",
      micode: {
        mi_code: "",
        mi_name: "",
        target_bill_no: "",
        target_bill_amount: "",
        revenue_supervisor_id: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
        .dispatch("MiCode/storeMiCode", this.micode)
        .then(() => {
          this.showToast = true;
          this.$toastr.s('success', "MiCode successfully created.", "MiCode Created");
          setTimeout(() => {
            this.$emit("onSavedMiCode");
          }, 350);
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
           this.showToast = true;
          for (let field of Object.keys(errors)) {
            this.$toastr.e("error", errors[field][0], "Error!");
          }
        });
    },
  }
};
</script>

<style scoped>
</style>
