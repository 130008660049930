<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Mi Code</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_micode.mi_code"
                  placeholder="Enter Mi Code"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Mi Name</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_micode.mi_name"
                  placeholder="Enter Mi Name"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Target Bill No</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_micode.target_bill_no"
                  placeholder="Enter Target Bill No"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Target Bill Amount</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_micode.target_bill_amount"
                  placeholder="Enter Target Bill Amount"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RS Code</label>
              <select required class="form-control" v-model="edited_micode.revenue_supervisor_id" id="">
                <option v-for="rscodeList in rscodeLists" :value="rscodeList.id">{{ rscodeList.rs_code }}</option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "EditMiCode",
  props: {
    micode: Object,
    rscodeLists: {
      type: Array,
      default: []
    }
  },
  data: () => {
    return {
      toastr: "",
      showToast: false,
      edited_micode: {
        id: 0,
        mi_code: "",
        mi_name: "",
        target_bill_no: "",
        target_bill_amount: "",
        revenue_supervisor_id: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
          .dispatch("MiCode/updateMiCode", this.edited_micode)
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "MiCode successfully updated.", "MiCode Updated");
            setTimeout(() => {
              this.$emit("onEditedMiCode");
            }, 350);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            for (let field of Object.keys(errors)) {
              this.showToast = true;
              this.$toastr.e("error, " + errors[field][0] + ", Error!");
            }
          });
    },
  },
  mounted() {
    this.edited_micode = {...this.micode};
  },
};
</script>

<style scoped>
</style>
